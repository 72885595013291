<template>
  <v-app style="background-color: #c9f7db;">
    <div class="items-container">
      <v-card>
        <v-row>
          <v-col cols="5" style="background-color: springgreen; margin: 0;">
            <img src="@/assets/icons/3.png" style="width: 100%; height: 100%;">
          </v-col>
          <v-col cols="5" style="margin: 40px 0 0 60px;">
            <center><img src="@/assets/icons/1.jpg" style="width: 150px; height: 150px;"></center>
            <center><span style="font-size: 25px; margin-bottom: 10px;">Login to complete your registration </span></center>
            <v-text-field label="Email" dense outlined v-model="username" v-validate="'required'" name="Username" @keyup="appear = false"></v-text-field>
            <span style="color: red;" v-if="valid">{{ errors.first('Username') }}</span>
            <v-text-field type="password" label="Password" dense outlined @keyup="appear = false" v-model="password"  @keyup.enter="login" v-validate="'required'" name="Password"></v-text-field>
            <span style="color: red;" v-if="valid">{{ errors.first('Password') }}</span>
            <center><CircleBars v-if="loading"/></center>
            <v-btn color="primary" block @click="login" v-if="visible"><span>Login</span></v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <v-snackbar
      :timeout="2000"
      :value="true"
      absolute
      top
      color="red"
      right
      text
      v-if="appear"
    >
      Your credentials are invalid
    </v-snackbar>
  </v-app>
</template>

<script>
import CircleBars from '../statistics/progress-bars/Widgets/CircleBars'
import { mapActions } from 'vuex'

export default {
  name: 'Register',
  components: {
    CircleBars,
  },
  data () {
    return {
      username: '',
      password: '',
      loading: false,
      visible: true,
      valid: true,
      appear: false,

    }
  },
  methods: {
    ...mapActions(['loguser']),
    async login () {
      if (this.username === '' || this.password === '') {
        this.save()
      } else {
        this.loading = true
        this.visible = false
        const data = {
          email: this.username,
          password: this.password,
        }
        try {
          const res = await this.axios.post('login', data)
          if (res.status === 200) {
            this.loading = false
            this.$store.dispatch('commitUpdateUserStatus', res.data.user_status)
            this.$router.push(localStorage.getItem('currentUrl') || '/admin/dashboard')
            localStorage.setItem('isLoggeIn', 'yes')
            localStorage.setItem('logged_user', JSON.stringify(res.data))
            console.log(this.$store.state.app.user_status)
          } else {
          }
        } catch (e) {
          if (e.response && e.response.status === 403) {
            setTimeout(() => {
              this.email = ''
              this.password = ''
            }, 1000)
            this.appear = true
            this.valid = false
            this.loading = false
            this.visible = true
          } else {
            console.log(e)
            this.appear = false
          }
        }
      }
    },
    async save () {
      this.$validator.validateAll().then(result => {
        if (result) {
        } else {
          // form have errors
        }
      })
    },
  },
}
</script>

<style scoped>
  .items-container {
    width: 1200px;
    margin: 100px 0 0 250px;
  }

  body {
    background-color: #c9f7db;
  }
</style>
